import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import I18n from '../enhancers/I18n';
import style from './Dashboard.module.scss';
import Loading from '../general/Loading';
import Greetings from './Greetings';
import PanelTicketMedio from './PanelTicketMedio';
import PanelProdutoPorAtendimento from './PanelProdutoPorAtendimento';
import PanelQuantidadeDeAtendimentos from './PanelQuantidadeDeAtendimentos';
import PanelPercentagemAproveitamentoContato from './PanelPercentagemAproveitamentoContatos';
// import PanelPercentagemContatosAgenda from './PanelPercentagemContatosAgenda';
import PanelMetaRealizadoMes from './PanelMetaRealizadoMes';
import PanelMetaMensalRealizadoLoja from './PanelMetaMensalRealizadoLoja';
import PanelOmniChannel from './PanelOmniChannel';

class StoreDashboard extends Component {
  get isSupervisor() {
    return (
      this.props.session &&
      this.props.session.user &&
      this.props.session.user.Perfil === 3
    );
  }

  getCurrentPeriod() {
    let date = new Date();
    if (date.getDate() === 1) {
      date.setDate(date.getDate() - 1);
    }

    const key = `month${date.getMonth() + 1}_short`;
    return (
      <React.Fragment>
        <I18n>{key}</I18n> / {date.getFullYear()}
      </React.Fragment>
    );
  }

  renderLoadingPanel = () => (
    <div className={style.sectionTitle}>
      <div className={style.container}>
        <Loading showText={true} />
      </div>
    </div>
  );

  isStoreGroup = () => this.props.storeId === 'all';

  renderPanels = (summary, overview) => {
    const isStoreGroup = this.isStoreGroup();
    const defaultProps = { summary, isStoreGroup, isStore: true };

    return (
      <React.Fragment>
        <PanelTicketMedio {...defaultProps} />
        <PanelProdutoPorAtendimento {...defaultProps} />
        <PanelQuantidadeDeAtendimentos {...defaultProps} />
        <PanelPercentagemAproveitamentoContato {...defaultProps} />
        {/*<PanelPercentagemContatosAgenda {...defaultProps} />*/}
        <PanelMetaRealizadoMes
          isSupervisor={this.isSupervisor}
          {...defaultProps}
        />
        <PanelMetaMensalRealizadoLoja tableData={overview} />
        <PanelOmniChannel {...defaultProps} />
      </React.Fragment>
    );
  };

  isLoading = (summary, overview) =>
    !summary ||
    !overview ||
    summary.status === 'loading' ||
    overview.status === 'loading';

  render() {
    const { resumos, overview } = this.props.stores;

    if (!resumos || !overview) {
      return null;
    }

    const storeId = this.props.storeId;
    const user = this.props.session.user;
    const summary = resumos[storeId];

    if (this.isLoading(summary, overview[storeId])) {
      return this.renderLoadingPanel();
    }

    const performanceText = `Acompanhe a performance do(a) ${
      summary.data.NomeLoja
    }`;

    return (
      <React.Fragment>
        <Greetings isManagerViewingStoreInfo={true} user={user} />
        <div className={style.sectionTitle}>
          <div className={style.container}>
            {!this.isStoreGroup() && performanceText}
            <strong>{this.getCurrentPeriod()}</strong>
          </div>
        </div>
        <div className={style.container}>
          <div className={style.panelsWrap}>
            {this.renderPanels(summary.data, overview[storeId].data)}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

StoreDashboard.propTypes = {
  user: PropTypes.object,
  seller: PropTypes.object
};

const mapStateToProps = ({ session, sellers, stores }) => ({
  session,
  sellers,
  stores
});

export default connect(mapStateToProps)(StoreDashboard);
