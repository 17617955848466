import React, { Component } from 'react';
import { connect } from 'react-redux';
import { loadStoreList } from '../../store/ducks/stores';
import CollapsableItem from '../panels/CollapsableItem';
import FormLayout from '../forms/FormLayout';
import DateInput from '../forms/DateInput';
import Button from '../forms/Button';
import style from './ReportPanel.module.scss';
import moment from 'moment';
import { getTenant } from '../../lib/utils';
import SelectBox from '../forms/SelectBox';

class ReportPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      report: {
        endpoint: props.endpoint,
        iniDate: '',
        endDate: '',
        store: '',
        error: ''
      }
    };
  }

  componentWillMount() {
    this.props.loadStoreList(this.props.user.VendedorId);
  }

  generateReport = reportType => {
    const idUser = this.props.user.Id;
    const codVendedor = this.props.user.VendedorId;
    const fields = this.props.fields;
    const data = this.state.report;
    const withDate = fields.includes('date');
    const withoutUsers = fields.includes('SEMUSER');

    if (withDate && !data.iniDate) {
      data.error = 'Data inicial não informada';
      this.setState({ report: data });
      return;
    }

    if (withDate && !data.endDate) {
      data.error = 'Data final não informada';
      this.setState({ report: data });
      return;
    }

    const { extraParams, settings } = this.props;
    const tenant = getTenant(settings);
    const endpoint = data.endpoint;
      let url = `http://srv02.guruvendas.com.br/ReportServer?%2fSSRS_SalesPush%2f${tenant}%2f${endpoint}&rs:Command=Render&rs:Format=${reportType}`;

    if (withDate) {
      const iniDate = moment(data.iniDate).format('YYYY-MM-DD');
      const endDate = moment(data.endDate).format('YYYY-MM-DD');
      url += `&Dt_Ini=${iniDate}&Dt_Fim=${endDate}`;
      }

      if (!withoutUsers) {
          if (endpoint === "Resumo_por_Agenda") {
              url += `&Cd_Vendedor=${codVendedor}`;
          }
          else {
              url += `&Cd_Usuario=${idUser}`;
          }
          
    }

    if (endpoint === 'Resumo_Vendas_Meta' && !data.store) {
      data.error = 'Loja obrigatório';
      alert('Loja obrigatório');
      this.setState({ report: data });
      return;
    }

    if (endpoint === 'Resumo_Operacao' && !data.store) {
      data.store = 'null';
    }

    if (data.store) {
      url += `&Cd_Loja=${data.store}`;
    }

    if (extraParams) {
      url += Object.keys(extraParams)
        .map(key => `&${key}=${extraParams[key]}`)
        .join('');
    }

    window.open(url);
  };

  handleDataChange(container, name, value) {
    const report = this.state[container];
    report[name] = value;
    this.setState({
      [container]: report
    });
  }

  renderDateField(label, event) {
    return (
      <div>
        <label className={style.label}>{label}</label>
        <DateInput onChange={event} />
      </div>
    );
  }

  getStoreList() {
    const storeList = this.props.stores.list;
    if (storeList.status === 'loaded' && !storeList.error) {
      return storeList.data
        .map(s => ({
          value: s.Id,
          name: s.Nome
        }))
        .sort((v1, v2) => (v1.name > v2.name ? 1 : -1));
    }
    return [];
  }

  renderStoreField() {
    return (
      <div>
        <SelectBox
          placeholder="Selecione uma Loja"
          loading={this.props.stores.list.status === 'loading'}
          items={this.getStoreList()}
          onChange={e => {
              this.handleDataChange('report', 'store', e.currentTarget.value);
          }}
        />
      </div>
    );
  }

  render() {
    const submitHandlerPDF = this.generateReport.bind(this, 'PDF');
    const submitHandlerExcel = this.generateReport.bind(this, 'Excel');
    const handleIniChange = this.handleDataChange.bind(
      this,
      'report',
      'iniDate'
    );
    const handleEndChange = this.handleDataChange.bind(
      this,
      'report',
      'endDate'
    );

    let fields = [];
    if (this.props.fields.includes('date')) {
      fields = fields.concat([
        this.renderDateField('Data Inicial', handleIniChange),
        this.renderDateField('Data Final', handleEndChange)
      ]);
    }

    if (this.props.fields.includes('stores')) {
      fields = fields.concat([this.renderStoreField('Loja')]);
    }

    return (
      <CollapsableItem title={this.props.title}>
        <FormLayout
          fields={fields}
          buttons={[
            <div className={style.buttonContainer}>
              <Button onClick={submitHandlerPDF}>Download PDF</Button>
              <Button onClick={submitHandlerExcel}>Download Excel</Button>
            </div>
          ]}
        />
      </CollapsableItem>
    );
  }
}

const mergeProps = (stateProps, dispatchProps, ownProps) => ({
  ...stateProps,
  ...dispatchProps,
  ...ownProps
});

export default connect(
  ({ settings, stores }) => ({ settings, stores }),
  { loadStoreList },
  mergeProps
)(ReportPanel);
