import React from 'react';
import PropTypes from 'prop-types';
import I18n from '../enhancers/I18n';
import style from './Dashboard.module.scss';
import Quote from '../panels/Quote';
import Loading from '../general/Loading';
import PanelTicketMedio from './PanelTicketMedio';
import ProductsByClient from './PanelProdutoPorAtendimento';
import PanelQuantidadeDeAtendimentos from './PanelQuantidadeDeAtendimentos';
import PanelPercentagemAproveitamentoContato from './PanelPercentagemAproveitamentoContatos';
// import PanelPercentagemContatosAgenda from './PanelPercentagemContatosAgenda';
import PanelConversaoDeContatosEmVenda from './PanelConversaoDeContatosEmVenda';
import PanelMetaRealizadoMes from './PanelMetaRealizadoMes';
import PanelSuaMetaRealizadoMes from './PanelSuaMetaRealizadoMes';
import ContactWarning from './ContactWarning';
import Greetings from './Greetings';
import PanelOmniChannel from './PanelOmniChannel';

const Dashboard = props => {
  const getCurrentPeriod = () => {
    let date = new Date();
    if (date.getDate() === 1) {
      date.setDate(date.getDate() - 1);
    }

    const key = `month${date.getMonth() + 1}_short`;
    return (
      <React.Fragment>
        <I18n>{key}</I18n> / {date.getFullYear()}
      </React.Fragment>
    );
  };

  const isManagerViewingStoreInfo = () => !!props.user.viewAs;

  const getSellerSummary = () => {
    const summary = props.seller.summary.data;
    const isManagerViewing = isManagerViewingStoreInfo();
    return (
      <React.Fragment>
        <PanelTicketMedio summary={summary} />
        <ProductsByClient summary={summary} />
        <PanelQuantidadeDeAtendimentos summary={summary} />
        <PanelPercentagemAproveitamentoContato summary={summary} />
        {/*<PanelPercentagemContatosAgenda summary={summary} />*/}
        <PanelConversaoDeContatosEmVenda summary={summary} />
        {!isManagerViewing && <PanelMetaRealizadoMes summary={summary} />}
        {isManagerViewing && <PanelSuaMetaRealizadoMes summary={summary} />}
        <PanelOmniChannel summary={summary} />
      </React.Fragment>
    );
  };

  const renderLoadingPanel = () => (
    <div className={style.sectionTitle}>
      <div className={style.container}>
        <Loading showText={true} />
      </div>
    </div>
  );

  const getPerformanceText = () => {
    if (props.user.Perfil === 1) {
      return `Acompanhe sua performance`;
    }
    return `Acompanhe a performance do(a) ${
      props.seller.summary.data.NomeVendedor
    }`;
  };

  const isLoading = seller =>
    !seller ||
    !seller.summary ||
    !seller.schedule ||
    seller.summary.status === 'loading' ||
    seller.schedule.status === 'loading';

  const render = () => {
    const { seller } = props;

    if (isLoading(seller)) {
      return renderLoadingPanel();
    }

    return (
      <React.Fragment>
        <Greetings
          isManagerViewingStoreInfo={isManagerViewingStoreInfo()}
          user={props.user}
          seller={props.seller}
        />
        <ContactWarning seller={props.seller} />
        <div className={style.sectionTitle}>
          <div className={style.container}>
            {getPerformanceText()}
            <strong>{getCurrentPeriod()}</strong>
          </div>
        </div>
        <div className={style.container}>
          <div className={style.panelsWrap}>{getSellerSummary()}</div>
        </div>
        <Quote />
        <ContactWarning seller={props.seller} />
      </React.Fragment>
    );
  };

  return render();
};

Dashboard.propTypes = {
  user: PropTypes.object,
  seller: PropTypes.object
};

export default Dashboard;
